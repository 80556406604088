import { useState, useEffect } from 'react';
import { guestServices } from '../api/services';
import { logout } from '../utils/auth';

const Dashboard = () => {
    const [guests, setGuests] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    const translateAllergy = (allergy) => {
        const translations = {
            'gluten': 'Gluten',
            'crustaceans': 'Crustáceos',
            'eggs': 'Huevos',
            'fish': 'Pescado',
            'peanuts': 'Cacahuetes',
            'soybeans': 'Soja',
            'milk': 'Leche',
            'nuts': 'Frutos secos',
            'celery': 'Apio',
            'mustard': 'Mostaza',
            'sesame': 'Sésamo',
            'sulphites': 'Sulfitos',
            'lupin': 'Altramuces',
            'molluscs': 'Moluscos'
        };
        return translations[allergy] || allergy;
    };

    const getMenuColor = (menuOption) => {
        switch (menuOption) {
            case 'normal':
                return 'bg-blue-100 text-blue-800';
            case 'child':
                return 'bg-yellow-100 text-yellow-800';
            case 'vegan':
                return 'bg-green-100 text-green-800';
            default:
                return 'bg-gray-100 text-gray-800';
        }
    };

    useEffect(() => {
        loadGuests();
    }, []);

    const loadGuests = async () => {
        try {
            const data = await guestServices.getGuests();
            setGuests(data);
        } catch (error) {
            setError('Error al cargar los invitados');
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleLogout = () => {
        logout();
    };

    if (loading) {
        return (
            <div className="min-h-screen flex items-center justify-center">
                <div className="text-xl">Cargando...</div>
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-gray-100">
            <nav className="bg-white shadow-lg">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex justify-between h-16">
                        <div className="flex items-center">
                            <h1 className="text-xl font-bold">Gestión de Invitados</h1>
                        </div>
                        <div className="flex items-center space-x-4">
                            <a
                                href="/statistics"
                                className="px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
                            >
                                Estadísticas
                            </a>
                            <button
                                onClick={handleLogout}
                                className="px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700"
                            >
                                Cerrar Sesión
                            </button>
                        </div>
                    </div>
                </div>
            </nav>

            <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                {error && (
                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
                        <span className="block sm:inline">{error}</span>
                    </div>
                )}

                <div className="px-4 py-6 sm:px-0">
                    <div className="bg-white shadow overflow-hidden sm:rounded-md">
                        <ul className="divide-y divide-gray-200">
                            {guests.map((guest, index) => {
                                const hasAccompanyingGuests = guest.accompanyingGuests && guest.accompanyingGuests.length > 0;
                                return (
                                    <li key={guest._id}>
                                        <div className={`px-4 py-4 sm:px-6 ${index % 2 === 0 ? 'bg-blue-50' : 'bg-pink-50'}`}>
                                            <div className="flex flex-col md:flex-row gap-4">
                                                {/* Invitado Principal */}
                                                <div className="flex-1 p-4 rounded-lg shadow-sm border border-gray-200 bg-white">
                                                    <div className="flex items-center justify-between">
                                                        <div className="text-sm font-medium text-indigo-600 truncate">
                                                            {guest.firstName} {guest.lastName}
                                                        </div>
                                                        <div className="ml-2 flex-shrink-0 flex">
                                                            <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                                                                guest.isAttending 
                                                                    ? 'bg-green-100 text-green-800'
                                                                    : 'bg-red-100 text-red-800'
                                                            }`}>
                                                                {guest.isAttending ? 'Asistirá' : 'No asistirá'}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="mt-2 sm:flex sm:justify-between">
                                                        <div className="sm:flex">
                                                            <p className={`flex items-center text-sm px-2 py-1 rounded-full ${getMenuColor(guest.menuOption)}`}>
                                                                Menú: {guest.menuOption}
                                                            </p>
                                                            <p className="mt-2 flex items-center text-sm sm:mt-0 sm:ml-6">
                                                                Servicio de Bus: <span className={`ml-1 ${
                                                                    guest.busService 
                                                                        ? 'text-green-600'
                                                                        : 'text-red-600'
                                                                }`}>
                                                                    {guest.busService ? 'SÍ' : 'NO'}
                                                                </span>
                                                            </p>
                                                        </div>
                                                        <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                                                            <p>
                                                                Acompañantes: <span className={hasAccompanyingGuests ? 'text-green-600' : 'text-red-600'}>
                                                                    {hasAccompanyingGuests ? 'SÍ' : 'NO'}
                                                                </span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    {guest.allergies && guest.allergies.length > 0 && (
                                                        <div className="mt-2">
                                                            <p className="text-sm text-gray-500">
                                                                Alergias: {guest.allergies.map(translateAllergy).join(', ')}
                                                            </p>
                                                        </div>
                                                    )}
                                                    {guest.customAllergy && (
                                                        <div className="mt-2">
                                                            <p className="text-sm text-gray-500">
                                                                Alergia personalizada: {guest.customAllergy}
                                                            </p>
                                                        </div>
                                                    )}
                                                </div>

                                                {/* Acompañantes */}
                                                {hasAccompanyingGuests && (
                                                    <div className="flex-1">
                                                        <h3 className="text-sm font-medium text-gray-900 mb-2">Acompañantes:</h3>
                                                        <div className="space-y-2">
                                                            {guest.accompanyingGuests.map((accompanying, index) => (
                                                                <div key={`${guest._id}-accompanying-${index}`} className="bg-white p-3 rounded-md border border-gray-200">
                                                                    <div className="flex items-center justify-between">
                                                                        <div className="text-sm font-medium text-gray-900">
                                                                            {accompanying.firstName} {accompanying.lastName}
                                                                        </div>
                                                                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                                                                            guest.isAttending 
                                                                                ? 'bg-green-100 text-green-800'
                                                                                : 'bg-red-100 text-red-800'
                                                                        }`}>
                                                                            {guest.isAttending ? 'Asistirá' : 'No asistirá'}
                                                                        </span>
                                                                    </div>
                                                                    <div className="mt-2">
                                                                        <p className={`text-sm px-2 py-1 rounded-full ${getMenuColor(accompanying.menuOption)}`}>
                                                                            Menú: {accompanying.menuOption}
                                                                        </p>
                                                                        {accompanying.allergies && accompanying.allergies.length > 0 && (
                                                                            <p className="text-sm text-gray-500 mt-1">
                                                                                Alergias: {accompanying.allergies.map(translateAllergy).join(', ')}
                                                                            </p>
                                                                        )}
                                                                        {accompanying.customAllergy && (
                                                                            <p className="text-sm text-gray-500 mt-1">
                                                                                Alergia personalizada: {accompanying.customAllergy}
                                                                            </p>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default Dashboard; 