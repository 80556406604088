import { useState, useEffect } from 'react';
import { guestServices } from '../api/services';
import { Link } from 'react-router-dom';

const Statistics = () => {
    const [guests, setGuests] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [stats, setStats] = useState({
        totalAttending: 0,
        totalBus: 0,
        menuCounts: {
            normal: 0,
            child: 0,
            vegan: 0
        }
    });

    useEffect(() => {
        loadGuests();
    }, []);

    const loadGuests = async () => {
        try {
            const data = await guestServices.getGuests();
            setGuests(data);
            calculateStats(data);
        } catch (error) {
            setError('Error al cargar los invitados');
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    const calculateStats = (guestsData) => {
        let totalAttending = 0;
        let totalBus = 0;
        const menuCounts = {
            normal: 0,
            child: 0,
            vegan: 0
        };

        guestsData.forEach(guest => {
            if (guest.isAttending) {
                // Contar invitado principal
                totalAttending++;
                menuCounts[guest.menuOption]++;

                // Contar acompañantes
                if (guest.accompanyingGuests && guest.accompanyingGuests.length > 0) {
                    guest.accompanyingGuests.forEach(accompanying => {
                        totalAttending++;
                        menuCounts[accompanying.menuOption]++;
                    });
                }

                // Contar bus
                if (guest.busService) {
                    // Contamos el invitado principal
                    totalBus++;
                    // Contamos los acompañantes si existen
                    if (guest.accompanyingGuests && guest.accompanyingGuests.length > 0) {
                        totalBus += guest.accompanyingGuests.length;
                    }
                }
            }
        });

        setStats({
            totalAttending,
            totalBus,
            menuCounts
        });
    };

    if (loading) {
        return (
            <div className="min-h-screen flex items-center justify-center">
                <div className="text-xl">Cargando...</div>
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-gray-100">
            <nav className="bg-white shadow-lg">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex justify-between h-16">
                        <div className="flex items-center">
                            <h1 className="text-xl font-bold">Estadísticas de Invitados</h1>
                        </div>
                        <div className="flex items-center space-x-4">
                            <Link
                                to="/dashboard"
                                className="px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
                            >
                                Volver a Inicio
                            </Link>
                        </div>
                    </div>
                </div>
            </nav>

            <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                {error && (
                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
                        <span className="block sm:inline">{error}</span>
                    </div>
                )}

                <div className="px-4 py-6 sm:px-0">
                    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                        <div className="px-4 py-5 sm:px-6">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                                Estadísticas de Invitados
                            </h3>
                        </div>
                        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">
                                        Total de Invitados Asistentes
                                    </dt>
                                    <dd className="mt-1 text-3xl font-semibold text-indigo-600">
                                        {stats.totalAttending}
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">
                                        Total de Plazas de Bus
                                    </dt>
                                    <dd className="mt-1 text-3xl font-semibold text-indigo-600">
                                        {stats.totalBus}
                                    </dd>
                                </div>
                                <div className="sm:col-span-2">
                                    <dt className="text-sm font-medium text-gray-500">
                                        Distribución de Menús
                                    </dt>
                                    <dd className="mt-1">
                                        <div className="grid grid-cols-3 gap-4">
                                            <div className="bg-blue-50 p-4 rounded-lg">
                                                <div className="text-sm font-medium text-blue-800">Menú Normal</div>
                                                <div className="mt-1 text-2xl font-semibold text-blue-600">
                                                    {stats.menuCounts.normal}
                                                </div>
                                            </div>
                                            <div className="bg-yellow-50 p-4 rounded-lg">
                                                <div className="text-sm font-medium text-yellow-800">Menú Niño</div>
                                                <div className="mt-1 text-2xl font-semibold text-yellow-600">
                                                    {stats.menuCounts.child}
                                                </div>
                                            </div>
                                            <div className="bg-green-50 p-4 rounded-lg">
                                                <div className="text-sm font-medium text-green-800">Menú Vegano</div>
                                                <div className="mt-1 text-2xl font-semibold text-green-600">
                                                    {stats.menuCounts.vegan}
                                                </div>
                                            </div>
                                        </div>
                                    </dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default Statistics; 