import api from './axios';


export const authServices = {
    login: async (credentials) => {
        try {
            const response = await api.post('/auth/login', credentials);
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    signup: async (userData) => {
        try {
            const response = await api.post('/auth/signup', userData);
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    verify: async () => {
        try {
            const response = await api.post('/auth/verify');
            return response.data;
        } catch (error) {
            throw error;
        }
    }
};


export const guestServices = {
    getGuests: async () => {
        try {
            const response = await api.get('/guest/list');
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    getGuest: async (id) => {
        try {
            const response = await api.get(`/guest/show/${id}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    createGuest: async (guestData) => {
        try {
            const response = await api.post('/guest/create', guestData);
            return response.data;
        } catch (error) {
            throw error;
        }
    }
}; 